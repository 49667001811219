import Backbone from 'backbone';
import * as encodingUtils from '@ardoq/html';
import type { BasicModel as BasicModelBackboneModel } from 'aqTypes';

import CurrentUser from 'models/currentUser';

// eslint-disable-next-line @typescript-eslint/no-unsafe-declaration-merging, @typescript-eslint/no-empty-object-type, @typescript-eslint/no-unused-vars
interface BasicModelClass extends BasicModelBackboneModel {}

// eslint-disable-next-line @typescript-eslint/no-unsafe-declaration-merging
declare class BasicModelClass
  extends Backbone.Model
  implements BasicModelBackboneModel
{
  constructor(attributes?: any, options?: any);
}

const BasicModel: typeof BasicModelClass = Backbone.Model.extend({
  _cachedParsedDescription: undefined,
  mustBeSaved: false,
  initialize: function () {},

  lock: function (this: BasicModelBackboneModel) {
    this.set('lock', CurrentUser!.id);
    this.save();
  },
  getLock: function (this: BasicModelBackboneModel) {
    return this.get('lock');
  },
  unlock: function (this: BasicModelBackboneModel) {
    if (CurrentUser!.canUnlock(this)) {
      this.set('lock', null);
      this.save();
    }
  },
  changedAndMustBeSaved: function (this: BasicModelBackboneModel) {
    return (!this.getLock() && this.mustBeSaved) || this.isNew();
  },
  name: function (this: BasicModelBackboneModel) {
    return encodingUtils.escapeHTML(this.get('name'));
  },
  getId: function (this: BasicModelBackboneModel) {
    return this.get('_id');
  },
  getDescription: function (this: BasicModelBackboneModel) {
    return this.get('description');
  },
  isSaved: function (this: BasicModelBackboneModel) {
    return this.get('_id') !== undefined;
  },
});

export default BasicModel;
